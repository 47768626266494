@charset "utf-8";

body.dark ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff;
}
body.dark :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  opacity: 1;
}
body.dark ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  opacity: 1;
}
body.dark :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}
body.dark ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}
@media (max-width: 1200px) {
  body.dark .tokyo_tm_all_wrap .leftpart {
    box-shadow: 0 0 20px #252525;
  }
}

body.dark ::placeholder {
  /* Most modern browsers support this now. */
  color: #fff;
}
body.dark #preloader:before,
body.dark #preloader:after {
  background-color: #fff;
}
body.dark .loader_line:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 1px;
  height: 0%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #000;
  -webkit-animation: lineheight 1000ms ease-in-out 0s forwards;
  -o-animation: lineheight 1000ms ease-in-out 0s forwards;
  animation: lineheight 1000ms ease-in-out 0s forwards;
}
body.dark .loader_line:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #ccc;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-animation: lineround 1200ms linear 0s infinite;
  -o-animation: lineround 1200ms linear 0s infinite;
  animation: lineround 1200ms linear 0s infinite;
  animation-delay: 2000ms;
}
body.dark {
  background-color: #000;
  color: #bbb;
}
body.dark h1,
body.dark h2,
body.dark h3,
body.dark h4,
body.dark h5,
body.dark h6 {
  color: #fff;
}
body.dark .tokyo_tm_all_wrap .leftpart {
  background-color: #000;
}
body.dark .menu_content {
  position: relative;
  text-decoration: none;
  transition: all .35s ease;

  &::before {
      content: "";
      position: absolute;
      width: 98%;
      height: 3px;
      bottom: -5px;
      left: 0;
      background-color: #fff;
      visibility: hidden;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
  }

  &:hover {
      &::before {
          visibility: visible;
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
      }
  }
}
body.dark .tokyo_tm_all_wrap .leftpart .menu > ul li {
  color: #bbb;
  &.react-tabs__tab.react-tabs__tab--selected {
    color: #fff;
  }
}
body.dark .tokyo_tm_all_wrap .leftpart .menu > ul li:hover {
  color: #fff;
}
body.dark .tokyo_tm_all_wrap .leftpart .copyright p {
  color: #bbb;
}
body.dark .tokyo_tm_all_wrap .leftpart .copyright a {
  color: #bbb;
}
body.dark .tokyo_tm_all_wrap .leftpart .copyright a:hover {
  color: #fff;
}
body.dark .tokyo_tm_all_wrap .rightpart {
  background-color: #111;
}
body.dark .tokyo_tm_all_wrap .rightpart_in {
  border-left-color: #202020;
}
body.dark .tokyo_tm_home .social li .svg {
  color: #fff;
}
body.dark .tokyo_tm_home .social li a {
  filter: invert(1);
  &:hover {
    filter: invert(0.8);
  }
}
body.dark .tokyo_tm_all_wrap .leftpart img {
  filter: invert(1);
}
body.dark .tokyo_tm_home .avatar .image {
}
body.dark .cursor-inner {
  margin-left: -3px;
  margin-top: -3px;
  width: 6px;
  height: 6px;
  z-index: 50;
  background-color: #fff;
  -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
body.dark .cursor-inner.cursor-hover {
  margin-left: -40px;
  margin-top: -40px;
  width: 80px;
  height: 80px;
  background-color: #fff;
  opacity: 0.3;
}
body.dark .cursor-outer {
  margin-left: -15px;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 50;
  opacity: 0.5;
  -webkit-transition: all 0.08s ease-out;
  transition: all 0.08s ease-out;
}
body.dark .cursor-outer.cursor-hover {
  opacity: 0;
}
body.dark .tokyo_tm_button .ib-button {
  background-color: #fff;
  color: #000;
}
body.dark .tokyo_tm_about .description .right > ul > li a {
  color: #bbb;
}
body.dark .tokyo_tm_about .description .right > ul > li a:hover {
  color: #fff;
}
body.dark .tokyo_tm_about .description .right > ul > li span {
  color: #fff;
}

body.dark .tokyo_tm_modalbox_about .counter ul li span {
  color: #bbb;
}
body.dark .tokyo_tm_modalbox_about .counter b {
  color: #fff;
}
body.dark .tokyo_tm_title span {
  background-color: rgba(255, 255, 255, 0.08);
  color: #999;
}
body.dark .tokyo_tm_portfolio .portfolio_filter ul li {
  color: #bbb !important;
  &.react-tabs__tab--selected {
    color: #fff !important;
  }
}
body.dark .tokyo_tm_portfolio .portfolio_filter ul li:hover {
  color: #fff;
}
body.dark .tokyo_tm_news ul li .details .title a {
  color: #fff;
}
body.dark .tokyo_tm_news ul li .details .date {
  color: #bbb;
}
body.dark .tokyo_tm_news ul li .details .date a {
  color: #bbb;
}
body.dark .tokyo_tm_news ul li .details .date a:hover {
  color: #fff;
}

body.dark .tokyo_tm_contact .fields ul li input {
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
}
body.dark .tokyo_tm_contact .fields .last textarea {
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
}
body.dark .tokyo_tm_contact .fields ul li input:focus {
  border: 1px solid rgba(255, 255, 255, 0.6);
}
body.dark .tokyo_tm_contact .fields textarea:focus {
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: #fff;
}

body.dark .footer-wrapper {
  background: #000;
}
body.dark .footer-wrapper .copyright a {
  color: #dedede;
}

body.dark .tokyo_tm_contact .fields textarea {
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  font-style: italic;
}

body.dark .tokyo_tm_news ul li .details {
  background-color: #000;
}
body.dark .tokyo_tm_news ul li .details .date {
  color: #bbb;
}
body.dark .tokyo_tm_news ul li .details .title {
  color: #fff;
}
body.dark .tokyo_tm_read_more a {
  color: #fff;
}
body.dark .tokyo_tm_read_more a:before {
  background-color: #fff;
}
body.dark .tokyo_tm_read_more a span {
  background-color: #000;
}
body.dark .tokyo_tm_news ul li .list_inner {
  box-shadow: 0 0 15px #252525;
}

body.dark .tokyo_tm_news ul li .image {
  background: #111;
}

body.dark {
  .tooltip-wrapper {
    h5 {
      color: #000 !important;
    }
  }
  .mymodal {
    border: none;
    background: #101010;
  }
  .box_inner {
    background: #151515;
    overflow-y: initial;
  }
  .description_wrap .image {
    z-index: initial;
  }
  .portfolio_tm_modalbox {
    .main_details .detailbox .first {
      color: #fff;
    }
    li .svg {
      filter: invert(1);
    }
  }
  .social {
    //margin-left: -5px;
    li .svg {
      transition: 0.3s;
    }
  }
  .news_share ul li .svg {
    filter: invert(1);
  }
  .tokyo_tm_modalbox_about .box-inner {
    background: #151515;
    .about_title h3 {
      color: #fff;
    }
    .progress_inner > span {
      color: #bbb;
    }
    .progress_inner .background .bar_in {
      background: #bbb;
    }
    .list_inner {
      border: 1px solid rgba(255, 255, 255, 0.5);
    }
    .partners .slick-list .slick-slide li img {
      filter: invert(1);
      background: #fff;
    }
  }

  .tokyo_tm_modalbox_news {
    .descriptions .bigger,
    .date,
    .date a {
      color: #bbb;
    }
    .main_content {
      .icon .svg {
        filter: invert(1);
      }
      .news_share span {
        color: #fff;
      }
    }
  }

  // audio player swicher
  .audio-player-switcher-label {
    .play-text {
      color: #fff;
    }
    .pause-text {
      color: #fff;
    }
    background-color: rgba(255, 255, 255, 0.2);
  }
}